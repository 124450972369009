<template>
  <div>
    <ejs-dialog
      allowDragging="true"
      :header="'홀아웃 처리 ' + (div === 'SAVE' ? '[적용]' : '[적용취소]')"
      isModal="true"
      ref="caddieArrangementHoleDivConfirmDialog"
      showCloseIcon="true"
      width="250"
      :animationSettings="{ effect: 'None' }"
      v-bind="caddieArrangementHoleDivConfirmDialogProps"
      :close="handleDialogClose"
    >
      <div class="window">
        <div class="windowFooter">
          <ul class="button">
            <li :class="div === 'SAVE' ? 'apply' : 'cancel'" class="keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  @click.native="teamApplyButtonClicked"
              >
                팀적용
              </erp-button>
            </li>
            <li :class="div === 'SAVE' ? 'apply' : 'cancel'">
              <erp-button
                  buttonDiv="SAVE"
                  @click.native="personApplyButtonClicked"
              >
                개인적용
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="handleCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "CaddieArrangementHoleDivConfirmPopup",
  components: {ErpButton},
  mixins: [commonMixin, confirmDialogMixin],
  props: {},
  create() {},
  data() {
    return {
      div: null,
    };
  },
  computed: {
    caddieArrangementHoleDivConfirmDialogProps() {
      return {
        animationSettings: { effect: "None" },
        position: { x: "center", y: "center" },
      };
    },
  },
  methods: {
    showPopup(args) {
      this.div = args.div;
    },
    handleDialogClose() {
      this.$emit("close");
    },
    handleCloseClick() {
      this.$refs.caddieArrangementHoleDivConfirmDialog.hide();
    },
    teamApplyButtonClicked() {
      this.$emit("popupConfirmed", { div: this.div, isTeamApply: true });
      this.handleCloseClick();
    },
    personApplyButtonClicked() {
      this.$emit("popupConfirmed", { div: this.div, isTeamApply: false });
      this.handleCloseClick();
    },
  },
};
</script>
