import request from "@/utils/request";

/**
 * @return Promise
 */
export function getCaddieArgmtInfoList(
  bsnDate,
  partDiv,
  notArrangementTimeFlag,
  rainCancelTimeFlag
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/deploy/info/list",
    method: "get",
    params: {
      bsnDate,
      partDiv,
      notArrangementTimeFlag,
      rainCancelTimeFlag,
    },
  });
}

/**
 * @return Promise
 */
export function getCaddieArgmtInfoDetaill(chkinId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/deploy/info/detail",
    method: "get",
    params: {
      chkinId,
    },
  });
}

/**
 * @return Promise
 */
export function getCaddieFee(
  caddieArgmtDiv,
  caddieBagDiv,
  caddieFeeDiv,
  holeDiv,
  visitDate,
  caddieId
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/deploy/info/caddie-fee",
    method: "get",
    params: {
      caddieArgmtDiv,
      caddieBagDiv,
      caddieFeeDiv,
      holeDiv,
      visitDate,
      caddieId
    },
  });
}

/**
 * @return Promise
 */
export function getCaddieArrangementPrint(chkinId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/deploy/info/print",
    method: "get",
    params: {
      chkinId,
    },
  });
}

/**
 * @return Promise
 */
export function getCaddieArrangementNotice() {
  //
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "game_management/deploy/info/print/ticket-message",
    method: "get",
  });
}

/**
 * @return Promise
 */
export function putCaddieArgmtInfo(
  addedRecords,
  changedRecords,
  deletedRecords,
  bsnDate
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/deploy/info/detail",
    method: "put",
    data: {
      addedRecords,
      changedRecords,
      deletedRecords,
      bsnDate,
    },
  });
}

/**
 * @return Promise
 */
export function updateChangeHoleDiv(
  visitId,
  teamBundleFlag,
  holeDiv,
  chgHoleDiv,
  caddieName,
  changeResn,
  visitDate
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/deploy/info/hole-change",
    method: "post",
    data: {
      visitId,
      teamBundleFlag,
      holeDiv,
      chgHoleDiv,
      caddieName,
      changeResn,
      visitDate,
    },
  });
}

/**
 * @return Promise
 */
export function updateAddVisitConfirmStateOK(checkinId, data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/game_management/deploy/info/add-visit-confirm/${checkinId}`,
    method: "put",
    data: data,
  });
}

/**
 * @return Promise
 */
export function updateCaddieArrangementNotice(
  addedRecords,
  changedRecords,
  deletedRecords
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/deploy/info/print/ticket-message",
    method: "put",
    data: {
      addedRecords,
      changedRecords,
      deletedRecords,
    },
  });
}

/**
 * @return Promise
 */
export function deleteChangeHoleDiv(visitId, teamBundleFlag, visitDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/deploy/info/hole-change",
    method: "delete",
    data: {
      visitId,
      teamBundleFlag,
      visitDate,
    },
  });
}
