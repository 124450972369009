<template>
  <div>
    <ejs-dialog
      ref="caddieArrangementSearchNameDialog"
      allowDragging="true"
      header="내장객/캐디 찾기"
      height="800"
      isModal="true"
      showCloseIcon="true"
      width="1180"
      :animationSettings="{ effect: 'None' }"
      v-bind="caddieArrangementSearchNameDialogProps"
      @open="handleDialogOpen"
      @close="handleDialogClose"
    >
      <div class="window builtInCustomerCaddySearch">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field name">
                    <div class="title">내장객명/락카번호/캐디명</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                          id="resveVisitNameTextBox"
                          v-model="resveVisitName"
                          @keypress.native="handleSearchQueryKeypress"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      buttonDiv="GET"
                      :isShortcutButton="true"
                      @click.native="handleSearchClick"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="caddieArrangementSearchNameGrid"
                      v-bind="caddieArrangementSearchNameGridProps"
                      @onGridDialogDoubleClickedOrEnterKeyed="
                        handleConfirmClick
                      "
                      @dataBound="onCaddieArrangementSearchNameGridDataBound"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  :isIconCustom="true"
                  @click.native="handleConfirmClick"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  :isIconCustom="true"
                  @click.native="handleCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
      <div style="display: none"></div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import { ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import { getFindVisitList } from "@/api/frontManagement";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {commonCodesGetSortNo} from "@/utils/commonCodes";
import { orderBy as _orderBy } from "lodash";

export default {
  name: "CaddieArrangementSearchNamePopup",
  components: { InputText, EjsGridWrapper, ErpButton },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectedBsnDate: {
      type: String,
      required: true,
    },
    selectedPartDiv: {
      type: String,
      required: true,
    },
  },
  mixins: [confirmDialogMixin],
  data() {
    return {
      resveVisitName: "",
      findVisitLists: [],
    };
  },
  methods: {
    // event handlers
    handleDialogOpen() {
      this.$emit("open");
    },
    handleDialogClose() {
      this.$emit("close");
    },
    handleSearchQueryKeypress({ key }) {
      if (key.toLowerCase() === "enter") {
        if (!this.resveVisitName) {
          this.errorToast("[내장객명/락카번호/캐디명] 을 입력해주세요");
          return;
        }

        this.search();
      }
    },
    handleSearchClick() {
      if (!this.resveVisitName) {
        this.errorToast("[내장객명/락카번호/캐디명] 을 입력해주세요");
        return;
      }

      this.search();
    },
    handleConfirmClick() {
      this.$emit("confirm", {
        data: this.$refs[
          "caddieArrangementSearchNameGrid"
        ].getSelectedRecords()[0],
      });
      this.hideDialog();
    },
    handleCloseClick() {
      this.hideDialog();
    },

    // features
    init() {
      this.resveVisitName = "";
      this.findVisitLists = [];
    },
    hideDialog() {
      this.$refs["caddieArrangementSearchNameDialog"].hide();
    },
    showDialog() {
      this.init();
      this.$refs["caddieArrangementSearchNameDialog"].show();
    },

    // API
    async search() {
      try {
        const {
          value: { findVisitLists },
        } = await getFindVisitList(
          this.selectedBsnDate,
          undefined,
          this.selectedPartDiv || undefined,
          this.resveVisitName,
          true
        );

        if (findVisitLists.length === 0) {
          this.errorToast("검색결과가 없습니다");
        } else {
          this.findVisitLists = _orderBy(findVisitLists.map((element) => {
            return {
              ...element,
              courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", element.startCourse),
            };
          }), ["startTime", "courseCodeSortNo", "partDiv", "visitSno"]);
        }
      } catch (e) {
        console.error(e);
      }
    },
    onCaddieArrangementSearchNameGridDataBound() {
      if (this.findVisitLists.length > 0) {
        this.$refs.caddieArrangementSearchNameGrid.selectRow(0);
      } else {
        document.getElementById("resveVisitNameTextBox").select();
      }
    },
  },
  computed: {
    caddieArrangementSearchNameDialogProps() {
      return {
        animationSettings: { effect: "None" },
        position: { x: "center", y: "center" },
        visible: this.visible,
      };
    },
    caddieArrangementSearchNameGridProps() {
      return {
        provides: [Resize, ForeignKey],
        allowResizing: true,
        allowSelection: true,
        isSelectedRowRetain: false,
        isInPopup: true,
        columns: [
          {
            groupCode: "PART_DIV",
            field: "partDiv",
            type: "string",
            isCommonCodeField: true,
            headerText: "부",
            minWidth: 16,
            textAlign: "Center",
            width: 80,
          },
          {
            field: "startTime",
            type: "string",
            headerText: "시간",
            minWidth: 16,
            textAlign: "Center",
            width: 65,
          },
          {
            groupCode: "COURSE_CODE",
            type: "string",
            field: "startCourse",
            isCommonCodeField: true,
            headerText: "코스",
            minWidth: 16,
            textAlign: "Center",
            width: 80,
          },
          {
            groupCode: "HOLE_DIV",
            type: "string",
            field: "holeDiv",
            isCommonCodeField: true,
            headerText: "홀",
            minWidth: 16,
            textAlign: "Center",
            width: 80,
          },
          {
            field: "resveName",
            type: "string",
            headerText: "예약자",
            minWidth: 16,
            textAlign: "Center",
            width: 65,
          },
          {
            field: "grpName",
            type: "string",
            headerText: "단체명",
            minWidth: 16,
            textAlign: "Center",
            width: 65,
          },
          {
            field: "visitName",
            type: "string",
            headerText: "내장객",
            minWidth: 16,
            textAlign: "Center",
            width: 65,
          },
          {
            field: "lockerNo",
            headerText: "락카",
            minWidth: 16,
            textAlign: "Center",
            type: "number",
            width: 65,
          },
          {
            field: "caddieName",
            type: "string",
            headerText: "캐디명",
            minWidth: 16,
            textAlign: "Center",
            width: 65,
          },
          {
            field: "chkinTime",
            type: "string",
            headerText: "C/I",
            minWidth: 16,
            textAlign: "Center",
            width: 65,
          },
          {
            field: "chkoutTime",
            type: "string",
            headerText: "C/O",
            minWidth: 16,
            textAlign: "Center",
            width: 65,
          },
        ],
        dataSource: this.findVisitLists,
        selectionSettings: { type: "Single", enableToggle: false },
      };
    },
  },
};
</script>
