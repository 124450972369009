<template>
  <div class="section-body">
    <div class="body-photo">
      <div
        v-if="memberLinkImagePath"
        class="item"
        :style="{
          backgroundImage: 'url(' + memberLinkImagePath + ')',
        }"
      ></div>
    </div>
    <ejs-grid-wrapper
      ref="linkMemberGrid"
      id="linkMemberGrid"
      :isSelectedRowRetain="false"
      :provides="linkProvides"
      :columns="linkColumns"
      :dataSource="memberLinkDataSource"
      @rowSelected="linkMemberGridSelected"
      :isShowProgress="false"
      :isAutoSelectCell="false"
    >
    </ejs-grid-wrapper>
  </div>
</template>

<style scoped>
.section-body:after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
.section-body .body-photo {float: left;width: 128px;height: 116px;background-size: 40%;}
.section-body .body-grid {float: left;width: calc(100% - 128px + 1px);height: 116px;margin-left: -1px;}
</style>

<script>
import { ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  gridUtilGetMemberNoColumnAccess,
  gridUtilGetTelColumnAccess,
} from "@/utils/gridUtil";
import { commonCodeGetComCodeByCurrentBizCode } from "@/utils/commonCodes";

export default {
  name: "linkMembers",
  props: {
    memberLinkList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ejsGridWrapper,
  },
  data() {
    return {
      selectedLinkMemberIdx: 0,
      linkProvides: [ForeignKey, Resize],
      linkColumns: [
        {
          field: "memberName",
          headerText: "회원명",
          type: "string",
          minWidth: 16,
          width: 90,
        },
        {
          allowEditing: true,
          field: "phoneNumber",
          headerText: "연락처",
          minWidth: 16,
          textAlign: "Center",
          width: 100,
          type: "string",
          valueAccessor: (field, data) =>
            gridUtilGetTelColumnAccess(field, data),
        },
        {
          field: "memberResveBen",
          headerText: "회원특전",
          type: "string",
          minWidth: 16,
          width: 240,
        },
        {
          field: "memberNumber",
          headerText: "회원번호",
          allowEditing: false,
          type: "string",
          isPrimaryKey: true,
          minWidth: 16,
          textAlign: "Center",
          width: 100,
          valueAccessor: (field, data) =>
            gridUtilGetMemberNoColumnAccess(field, data),
        },
        {
          field: "memberDivision",
          headerText: "회원구분",
          minwidth: 16,
          width: 90,
          textAlign: "Center",
          isCommonCodeField: true,
          dataSource: commonCodeGetComCodeByCurrentBizCode("MEMBER_DIV"),
          type: "string",
        },
        {
          field: "benefitDescription",
          headerText: "비고",
          type: "string",
          minWidth: 16,
          width: 240,
        },
      ],
    };
  },
  computed: {
    memberLinkDataSource() {
      return this.memberLinkList.map(
          (memberLinkInfo) => ({
            memberName: memberLinkInfo.member?.name,
            phoneNumber: memberLinkInfo.member?.phoneNumber,
            memberResveBen: memberLinkInfo.memberResveBen,
            memberNumber: memberLinkInfo.memberNumber,
            memberDivision: memberLinkInfo.memberDivision,
            benefitDescription: memberLinkInfo.benefitDescription,
            memberImagePath: memberLinkInfo.member?.imageFile?.path,
          })
      );
    },
    memberLinkImagePath() {
      if (
          this.memberLinkDataSource.length > this.selectedLinkMemberIdx &&
          this.memberLinkDataSource[this.selectedLinkMemberIdx].memberImagePath
      ) {
        return this.memberLinkDataSource[this.selectedLinkMemberIdx]
            .memberImagePath;
      } else {
        return null;
      }
    },
  },
  methods: {
    linkMemberGridSelected(args) {
      this.selectedLinkMemberIdx = args.rowIndex;
    },
  },
};
</script>
