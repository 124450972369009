<template>
  <div>
    <ejs-dialog
      allowDragging="true"
      header="홀아웃 처리"
      isModal="true"
      ref="caddieArrangementHoleDivDialog"
      showCloseIcon="true"
      width="400"
      :animationSettings="{ effect: 'None' }"
      v-bind="caddieArrangementHoleDivDialogProps"
      :close="handleDialogClose"
    >
      <div class="window halloutProcessing">
        <div class="windowContent">
          <div class="content-wrapper">
            <article class="body-article">
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section class="article-section section-01">
                <div class="section-body">
                  <div class="body-data">
                    <div class="data-outer">
                      <div class="data-inner">
                        <ul class="data-content">
                          <li class="field roundHall">
                            <!-- 필수 : required -->
                            <div class="title">라운드 홀</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group text">
                                  {{ originHoleDiv }} →
                                  <div class="validation">
                                    <div class="e-tip-content"></div>
                                    <div class="e-tip-top"></div>
                                  </div>
                                </li>
                                <li class="item form-group select">
                                  <div class="form">
                                    <ejs-dropdownlist
                                      cssClass="body-data-dropdown"
                                      v-bind="changeHoleDivDropdownListProps"
                                      v-model="chgHoleDiv"
                                    />
                                  </div>
                                  <div class="validation">
                                    <div class="e-tip-content"></div>
                                    <div class="e-tip-top"></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title">캐디명</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <div class="form">
                                    <input-text
                                      class="lookup-condition-textbox"
                                      v-model="caddieName"
                                    />
                                  </div>
                                  <div class="validation">
                                    <div class="e-tip-content"></div>
                                    <div class="e-tip-top"></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title">변경사유</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <div class="form">
                                    <input-textarea
                                      class="lookup-condition-textarea"
                                      v-model="changeResn"
                                    />
                                  </div>
                                  <div class="validation">
                                    <div class="e-tip-content"></div>
                                    <div class="e-tip-top"></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                    <div class="border-left"></div>
                    <div class="border-right"></div>
                    <div class="border-top"></div>
                    <div class="border-bottom"></div>
                  </div>
                </div>
              </section>
            </article>
            <div style="display: none">
              <div class="content-lookup">
                <div class="lookup-left">
                  <ul class="lookup-condition">
                    <li class="field">
                      <div class="title"></div>
                      <ul class="content">
                        <li class="item">
                          {{ originHoleDiv }} ->
                          <ejs-dropdownlist
                            width="100"
                            cssClass="lookup-condition-dropdown"
                            v-bind="changeHoleDivDropdownListProps"
                            v-model="chgHoleDiv"
                          />
                        </li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title"></div>
                      <ul class="content">
                        <li class="item"></li>
                      </ul>
                    </li>
                    <li class="field">
                      <div class="title"></div>
                      <ul class="content">
                        <li class="item"></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="apply keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  :is-icon-custom="true"
                  @click.native="handleSaveClick"
              >
                적용
              </erp-button>
            </li>
            <li class="cancel">
              <erp-button
                  buttonDiv="SAVE"
                  :is-icon-custom="true"
                  @click.native="handleResetClick"
              >
                적용취소
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  :is-icon-custom="true"
                  @click.native="handleCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
        <caddie-arrangement-hole-div-confirm-popup
          v-if="isCaddieArrangementHoleDivConfirmPopupOpen"
          ref="caddie-arrangement-hole-div-confirm-popup"
          @popupConfirmed="handleCaddieArrangementHoleDivConfirmPopupCloseConfirm"
          @close="handleCaddieArrangementHoleDivConfirmPopupClose"
        />
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { deleteChangeHoleDiv, updateChangeHoleDiv } from "@/api/caddieArgmt";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { mapGetters } from "vuex";
import CaddieArrangementHoleDivConfirmPopup from "@/views/game-progress/caddie-arrangement/CaddieArrangementHoleDivConfirmPopup";

export default {
  name: "CaddieArrangementHoleDivPopup",
  components: {
    InputTextarea,
    InputText,
    CaddieArrangementHoleDivConfirmPopup,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  create() {
    this.init();
  },
  data() {
    return {
      isCaddieArrangementHoleDivConfirmPopupOpen: false,
      visitor: {},
      chgHoleDiv: "",
      caddieName: "", // TODO 로그인 사용자 이름 Default
      changeResn: "",
      visitDate: null,
    };
  },
  methods: {
    init() {
      this.visitor = {};
      this.chgHoleDiv = "";
      this.caddieName = this.username;
      this.changeResn = "";
      this.visitDate = null;
    },
    hideDialog() {
      this.$refs["caddieArrangementHoleDivDialog"].hide();
    },
    showDialog(selectedVisitor, visitDate) {
      this.init();
      this.visitor = JSON.parse(JSON.stringify(selectedVisitor));
      this.visitDate = visitDate;
      this.chgHoleDiv =
        this.visitor.changeHoleDiv &&
        this.visitor.changeHoleDiv !== this.visitor.holeDiv
          ? this.visitor.changeHoleDiv
          : this.changeHoleDivList[0].comCode;

      this.$refs["caddieArrangementHoleDivDialog"].show();
    },
    handleDialogClose() {
      this.$emit("close");
    },
    handleCaddieArrangementHoleDivConfirmPopupClose() {
      this.isCaddieArrangementHoleDivConfirmPopupOpen = false;
      // this.handleCloseClick();
    },
    async handleCaddieArrangementHoleDivConfirmPopupCloseConfirm(data) {
      this.isCaddieArrangementHoleDivConfirmPopupOpen = false;
      if (data && data.div === "SAVE") {
        if (data.isTeamApply) {
          await this.updateChangeHoleDiv(true).then(() => this.hideDialog());
        } else {
          await this.updateChangeHoleDiv(false).then(() => this.hideDialog());
        }
      } else if (data && data.div === "RESET") {
        if (data.isTeamApply) {
          await this.deleteChangeHoleDiv(true).then(() => this.hideDialog());
        } else {
          await this.deleteChangeHoleDiv(false).then(() => this.hideDialog());
        }
      }
    },
    handleSaveClick() {
      if (!this.caddieName) {
        this.errorToast("캐디명을 입력해주세요");
        return;
      }

      this.caddieArrangementHoleDivConfirmPopupOpen({ div: "SAVE" });
      // this.updateChangeHoleDiv().then(() => this.hideDialog());
    },
    handleResetClick() {
      this.caddieArrangementHoleDivConfirmPopupOpen({ div: "RESET" });
      // this.deleteChangeHoleDiv().then(() => this.hideDialog());
    },
    handleCloseClick() {
      this.hideDialog();
    },
    caddieArrangementHoleDivConfirmPopupOpen(data) {
      this.isCaddieArrangementHoleDivConfirmPopupOpen = true;
      this.$nextTick(() => {
        this.$refs["caddie-arrangement-hole-div-confirm-popup"].showPopup(data);
      });
    },
    // API
    async updateChangeHoleDiv(applyFlag) {
      try {
        await updateChangeHoleDiv(
          this.visitor.visitId,
          applyFlag,
          // await this.confirm("모든 방문자에 적용하시겠습니까?"),
          this.visitor.holeDiv,
          this.chgHoleDiv,
          this.caddieName,
          this.changeResn || undefined,
          this.visitDate
        );

        (async () => {
          this.$EventBus.$emit("updateChangeHoleDiv");
          this.infoToast("적용 완료");
        })();
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async deleteChangeHoleDiv(applyFlag) {
      try {
        await deleteChangeHoleDiv(
          this.visitor.visitId,
          applyFlag,
          // await this.confirm("모든 방문자에 적용하시겠습니까?"),
          this.visitDate
        );

        (async () => {
          this.$EventBus.$emit("updateChangeHoleDiv");
          this.infoToast("적용 완료");
        })();
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
  computed: {
    ...mapGetters(["username"]),
    holeDivList() {
      return commonCodesGetCommonCode("HOLE_DIV");
    },
    caddieArrangementHoleDivDialogProps() {
      return {
        animationSettings: { effect: "None" },
        position: { x: "center", y: "center" },
        visible: this.visible,
      };
    },
    originHoleDiv() {
      const holeCommonCode = this.holeDivList.find(
        ({ comCode }) => comCode === this.visitor.holeDiv
      );

      return holeCommonCode ? holeCommonCode.comName : "";
    },
    changeHoleDivList() {
      return this.holeDivList.filter(
        ({ comCode }) => comCode !== this.visitor.holeDiv
      );
    },
    changeHoleDivDropdownListProps() {
      return {
        dataSource: this.changeHoleDivList,
        fields: { text: "comName", value: "comCode" },
      };
    },
  },
};
</script>
