<template>
  <div>
    <ejs-dialog
      ref="caddieArrangementPrintPopupDialog"
      allowDragging="true"
      header="캐디 배치표 출력"
      isModal="true"
      showCloseIcon="true"
      width="365"
      :animationSettings="{ effect: 'None' }"
      v-bind="caddieArrangementPrintPopupDialogProps"
      @open="handleDialogOpen"
      @close="handleDialogClose"
    >
      <div class="window caddyLayoutTableOutput">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-box">
                      <div class="title">캐디 배치표</div>
                      <ul class="start">
                        <li>
                          <div>
                            배치일자 :
                            [{{ caddieArgmtPrintData.timeData.visitDate }}] <span style="padding-right: 20px"></span>
                          </div>
                        </li>
                        <li>
                          예약시간 :
                          [{{ caddieArgmtPrintData.timeData.startTime }}]
                        </li>
                        <br/>
                      </ul>
                      <div class="information paddingBottm">
                        <ul class="header">
                          <li class="locker">락카</li>
                          <li class="name" style="padding-right: 40px">내장객명(구분)</li>
                          <li class="name" style="padding-left: 74px">백네임</li>
                        </ul>
                        <ul class="content">
                          <li
                            v-for="teamVisitInfo in caddieArgmtPrintData.teamVisitInfoList"
                            v-bind:key="`teamVisitInfo-${teamVisitInfo.visitId}`"
                          >
                            <ul>
                              <li class="locker">
                                {{ teamVisitInfo.lockerPrtNo }}
                              </li>
                              <li class="name" style="width: 180px">
                                {{ teamVisitInfo.visitorInfo }}
                              </li>
                              <li class="name">
                                {{ teamVisitInfo.bagName }}
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div class="information paddingTop">
                        <ul class="header">
                          <li class="caddieNameAndNo" style="width: 140px">캐디(No)</li>
                          <li class="cart">(카트)</li>
                        </ul>
                        <ul class="content">
                          <li
                              v-for="teamVisitInfo in caddieArgmtPrintData.teamVisitInfoList"
                              v-bind:key="`teamVisitInfo-${teamVisitInfo.visitId}`"
                          >
                            <ul>
                              <li class="caddieNameAndNo" style="width: 145px">
                                {{ teamVisitInfo.caddieNameAndNo }}
                              </li>
                              <li class="cart"> {{ teamVisitInfo.cartNo }}</li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div class="grpName">
                          <div v-if="caddieArgmtPrintData.grpName">단 체 명 : [{{ caddieArgmtPrintData.grpName }}]</div>
                      </div>
                      <br/>
                      <div class="company">
                        {{ caddieArgmtPrintData.ccName }}
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="print keyColor">
              <erp-button
                  buttonDiv="PRINT"
                  @click.native="handlePrintClick"
                  :isIconCustom="true"
              >
                출력
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="handleCloseClick"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .window.caddyLayoutTableOutput .windowContent .article-section.section-01 .body-box {padding: 12px;}
body .window.caddyLayoutTableOutput .windowContent .article-section.section-01 .body-box .information .header li {padding-right: 7px;}
body .window.caddyLayoutTableOutput .windowContent .article-section.section-01 .body-box .information .content {border:0}
body .window.caddyLayoutTableOutput .windowContent .article-section.section-01 .body-box .paddingBottm { padding-bottom: 0 !important; }
body .window.caddyLayoutTableOutput .windowContent .article-section.section-01 .body-box .paddingTop { padding-top: 0 !important; }
body .window.caddyLayoutTableOutput .windowContent .article-section.section-01 .body-box .paddingBottm .content ul li { font-size: 16px;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import { commonCodesGetComName } from "@/utils/commonCodes";
import { getTodayDateTime } from "@/utils/date";
import BillPrintUtil from "@/utils/billPrintUtil";
import {getCaddieArrangementNotice, getCaddieArrangementPrint} from "@/api/caddieArgmt";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "CaddieArrangementPrintPopup",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components:{
    ErpButton,
  },
  mixins: [confirmDialogMixin],
  created: function () {
    this.init();
  },
  data() {
    return {
      caddieArgmtPrintData: {
        grpName: null,
        startTime: null,
        startCourse: null,
        ccName: [],
        timeData: {},
        teamVisitInfoList: [],
        caddieArgmtInfoList: [],
      },
    };
  },
  methods: {
    commonCodesGetComName,
    init() {
      this.caddieArgmtPrintData = {
        grpName: null,
        startTime: null,
        startCourse: null,
        ccName: [],
        timeData: {},
        teamVisitInfoList: [],
        caddieArgmtInfoList: [],
      };
    },
    hideDialog() {
      this.$refs["caddieArrangementPrintPopupDialog"].hide();
    },
    showDialog(chkinId, grpName) {
      this.init();
      this.getCaddieArrangementPrint(chkinId, grpName).then(() => {
        this.$refs["caddieArrangementPrintPopupDialog"].show();
      });
    },
    handleDialogOpen() {
      this.$emit("open");
    },
    handleDialogClose() {
      this.$emit("close");
    },
    async handlePrintClick() {
      // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.

      let {
        value: { tcketNtcnList },
      } = await getCaddieArrangementNotice();

      tcketNtcnList = tcketNtcnList.filter(data => data.useFlag).map(data => {
        return data.ntcnMessage;
      });

      const tcketNtcn = tcketNtcnList.join("\n");
      const printSetData = {
        startTime: this.caddieArgmtPrintData.timeData.startTime,
        startCourse: this.startCourseName,
        visitInfoList: this.caddieArgmtPrintData.teamVisitInfoList.map((teamVisitInfo, index) => {
          const caddieNameAndNo = this.caddieArgmtPrintData.caddieArgmtInfoList.map(caddie => caddie.caddieNameAndNo);
          const cartNo = this.caddieArgmtPrintData.caddieArgmtInfoList.map(caddie => caddie.cartNo);

          return {
            ...teamVisitInfo,
            caddieNameAndNo: caddieNameAndNo[index],
            cartNo: cartNo[index],
          };
        }),
        //TODO: !!!!!
        caddieInfoList: this.caddieArgmtPrintData?.caddieArgmtInfoList?.length > 0 ?
            this.caddieArgmtPrintData.caddieArgmtInfoList : this.createDummyCaddieList,
        ...(this.caddieArgmtPrintData.grpName && {grpName: this.caddieArgmtPrintData.grpName}),
        ...(this.caddieArgmtPrintData.grpName && {grpNameTitle: "단 체 명 : "}),
        ccName: this.caddieArgmtPrintData.ccName,
        visitDate: this.caddieArgmtPrintData.timeData.visitDate,
        currentDate: getTodayDateTime(),
        ntcnMessage: tcketNtcn
      };
      const config = {
        "useBillPrinter": true,
        "useKitchenPrinter": true,
        "useKitchenPrinterCheck" : false
      };
      console.log(printSetData);
      await BillPrintUtil.getTsConfReceiptInfo.call(this, "CADDIE_ARGMT", true, printSetData, config);

    },
    handleCloseClick() {
      this.hideDialog();
    },

    // API
    async getCaddieArrangementPrint(chkinId, grpName) {
      try {
        const {
          value: {ccName, timeData, teamVisitInfoList, caddieArgmtInfoList},
        } = await getCaddieArrangementPrint(chkinId);
        this.caddieArgmtPrintData = {
          ccName,
          timeData,
          grpName,
          teamVisitInfoList: teamVisitInfoList.map((visitor, index)=> ({
            ...visitor,
            memberDivName: commonCodesGetComName("MEMBER_DIV", visitor.memberDiv),
            sexCodeName: commonCodesGetComName("SEX_CODE", visitor.sexCode),
            visitorInfo: commonCodesGetComName("SEX_CODE", visitor.sexCode) + "/" + visitor.visitName + "(" + commonCodesGetComName("MEMBER_DIV", visitor.memberDiv) + ")",
            lockerPrtNo: visitor.lockerNo === '000' || visitor.lockerNo === '999'
                ? "미배정"
                : visitor.lockerPrtNo,
            caddieNameAndNo : caddieArgmtInfoList.map(caddie => (caddie.caddieName + "(" + caddie.caddieNo + ")" ))[index],
            cartNo: caddieArgmtInfoList.map(caddie => (caddie.cartNo))[index],
          })),
          caddieArgmtInfoList: caddieArgmtInfoList.map(caddie => ({
            ...caddie,
            caddieNameAndNo: caddie.caddieName + "(" + caddie.caddieNo + ")",
            cartNo: caddie.cartNo,
          })),
        };
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
  computed: {
    caddieArrangementPrintPopupDialogProps() {
      return {
        animationSettings: { effect: "None" },
        position: { x: "center", y: "center" },
        visible: this.visible,
      };
    },
    startCourseName() {
      return commonCodesGetComName(
        "COURSE_CODE",
        this.caddieArgmtPrintData.timeData.startCourse
      );
    },
    createDummyCaddieList() {
      return ([{
        caddieName: "   ",
        caddieNo: "   ",
        cartNo: "   ",
      }]);
    },
    // groupName() {
    //
    // },
  },
};
</script>
