<template>
  <div id="popupWrapper">
    <ejs-dialog
        ref="builtInGuestNotificationTalkSendPopup"
        header="내장객 알림톡 전송"
        allowDragging="true"
        showCloseIcon="true"
        isModal="true"
        width="470"
        height="880"
        :animationSettings="{ effect: 'None' }"
        @close="onBuiltInGuestNotificationTalkSendPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article" style="height: 75%">
                <section
                    class="article-section section-01"
                    style="height: 400px;"
                >
                  <div class="sms-send-box">
                    <div class="preview-top">
                      <div class="speaker-box"/>
                      <div class="speaker-small-box"/>
                    </div>
                    <div class="preview-middle">
                      <div class="screen-box">
                        <div class="user-profile">
                          <div class="image">{{ username.substr(0, 1) }}</div>
                          <div class="name">{{ username }}</div>
                        </div>
                        <div class="message-box">
                          [Web발신]
                          <br/>
                          <div
                              class="preview"
                              v-html="smsSendInfo.message.replace(/\n/g, '<br>')"
                              v-if="smsSendInfo.message"
                          />
                          <br
                              v-if="
                              smsSendInfo.message && smsSendInfo.aditWordsFlag
                            "
                          />
                          <textarea
                              ref="aditWords"
                              placeholder="메시지를 입력해주세요"
                              v-model="aditWords"
                              :maxlength="maxSmsByteLength"
                              @keyup="resize"
                              @keydown="resize"
                              @focus="resize"
                              spellcheck="false"
                              autofocus
                              v-if="smsSendInfo.aditWordsFlag"
                          />
                          <div
                              class="preview"
                              v-if="
                              smsSendInfo.bizNameLocation === 'DOWN' &&
                              smsSendInfo.bizName
                            "
                          >
                            [HEADER_MEMO]$
                          </div>
                        </div>
                        <div
                            class="limit"
                            :style="{
                            color:
                              getByteLengthSum(aditWords) >
                              maxSmsByteLength
                                ? 'red'
                                : '#333',
                          }"
                        >
                          {{
                            numberWithCommas(getByteLengthSum(aditWords))
                          }}
                          / {{ numberWithCommas(maxSmsByteLength) }} Byte ({{
                            getByteLengthSum(aditWords) >= 90
                                ? "LMS"
                                : "SMS"
                          }})
                        </div>
                      </div>
                    </div>
                    <div class="preview-bottom">
                      <div class="button-box"/>
                    </div>
                  </div>
                </section>
                <section
                    class="article-section section-02"
                    style="height: 45px;"
                >
                  <div class="section-body" style="border: none; overflow: hidden !important;">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="lookup-condition">
                            <li class="field subdivision">
                              <div class="title">전송타입</div>
                              <ul class="content" style="width: 120px;">
                                <li class="item">
                                  <ejs-dropdownlist
                                      v-model="smsSendInfo.type"
                                      :dataSource="smsSendTypeOptionsList"
                                      :fields="commonCodeFields"
                                      :allowFiltering="false"
                                      cssClass="body-condition-dropdown"
                                      @select="onSmsSendTypeSelect"
                                  />
                                </li>
                              </ul>
                            </li>
                            <li class="field" style="margin-left: 110px;">
                              <ul class="content">
                                <li class="item">
                                  <ul class="check">
                                    <li>
                                      <label>
                                        <input
                                            type="radio"
                                            value="TEAM"
                                            v-model="byTeamOrGroup"
                                            name="isOver"
                                        />
                                        <i/>
                                        <div class="label">팀별</div>
                                      </label>
                                    </li>
                                    <li>
                                      <label>
                                        <input
                                            type="radio"
                                            value="GROUP"
                                            v-model="byTeamOrGroup"
                                            name="isOver"
                                            :disabled="!grpNo"
                                        />
                                        <i/>
                                        <div class="label">단체별</div>
                                      </label>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="article-section section-01" style="height: 335px;">
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                          ref="builtInGuestGrid"
                          v-bind="builtInGuestGridProps"
                          :dataSource="teamInformation"
                          @queryCellInfo="queryCellInfo"
                          @gridCheckboxChanged="gridCheckboxChanged"
                          @headerSelectAllCheckboxClicked="onGridHeaderSelectAllCheckboxClicked"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-custom-shortcut-button="true"
                  :shortcut="sendSmsButtonProps.shortcut"
                  :shortcut-key="sendSmsButtonProps.shortcutKey"
                  :ignore="showTestSmsSendPopup"
                  @click.native="onSmsSendButtonClicked"
              >
                전송
              </erp-button>
            </li>
            <li>
              <erp-button button-div="SAVE" @click.native="onTestButtonClicked">
                검증
              </erp-button>
            </li>
            <li class="reset">
              <erp-button button-div="GET" @click.native="onInitButtonClicked">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closeBatchSmsSendPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <test-sms-send-popup
        v-if="showTestSmsSendPopup"
        ref="testSmsSendPopup"
        @smsSend="smsSend"
        @popupClosed="onTestSmsSendPopup"
    />
  </div>
</template>

<script>
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {mapGetters} from "vuex";
import {SHORTCUT_KEYS} from "@/utils/KeyboardUtil";
import TestSmsSendPopup from "@/components/popups/TestSmsSendPopup";
import ErpButton from "@/components/button/ErpButton";
import EjsGridWrapper from '@/components/common/EjsGridWrapper';
import {numberWithCommas} from "@/utils/number";
import {getByteLength, getFormattedTelNumber} from "@/utils/string";
import {validateFormRefs} from "@/utils/formUtil";
import {gridUtilGetTelColumnAccess} from "@/utils/gridUtil";
import {
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetCommonCodeByIdxAttrb,
  commonCodesGetStandardInfo
} from "@/utils/commonCodes";
import {getVisitorInfoByCaddieArrangeMent} from "@/api/caddieInfoRegistration";
import {Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getFormattedDate} from "@/utils/date";

export default {
  name: "BuiltInGuestNotificationTalkSendPopup",
  components: {TestSmsSendPopup, ErpButton, EjsGridWrapper},
  mixins: [confirmDialogMixin],
  props: {
    visitDate: {
      type: String,
      required: true,
    },
    chkinId: {
      type: Number,
      required: true,
    },
    grpNo: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      maxSmsByteLength: 2000,
      commonCodeFields: {text: "smsSendTypeName", value: "smsSendType"},
      smsSendData: null,
      showTestSmsSendPopup: false,
      smsSendInfo: {
        message: "",
        bizName: commonCodesGetStandardInfo("bizName"),
        bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
        aditWordsFlag: true,
        transSmsSendFlag: false,
        reservedFlag: false,
        resveSendDate: null,
        resveSendTime: "00:00",
        dsptchNo: null,
        kakaoSendFlag: false,
        smsKind: null,
        kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
        kakaoTemplateCode: null,
        subject: null,
      },
      searchOptions: {
        smsSendTypeOptions: null,
      },
      popupData: null,
      smsSendValidationRules: {
        message: {
          required: true,
        },
        dsptchNo: {
          required: true,
        },
      },
      smsWords: null,
      aditWords: "",
      byTeamOrGroup: "TEAM",
      teamInformation: [],
      toExcludeIndexList: [],
      smsSendTypeOptionsList: [],
    };
  },
  computed: {
    ...mapGetters(["username"]),
    sendSmsButtonProps() {
      return {
        shortcutKey: "BuiltInGuestNotificationTalkSendPopup.shortcuts.sendSms",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F7,
        },
      };
    },
    builtInGuestGridProps() {
      return {
        provides: [
          Filter,
          Edit,
          Resize,
          Page,
          ExcelExport,
          ForeignKey,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        allowExcelExport: true,
        allowSorting: false,
        allowFiltering: false,
        allowPaging: true,
        pageSettings: {pageSize: 10},
        noColumnWidth: 33,
        columns: [
          {
            field: "resveNo",
            visible: false,
            isPrimaryKey: true,
          },
          {
            field: "select",
            headerText: "",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            width: 30,
            textAlign: "center",
            allowFiltering: false,
            allowSorting: false,
            isSelectAllColumn: true,
          },
          {
            field: 'startTime',
            headerText: '시간',
            width: 50,
            textAlign: 'center',
            allowEditing: false,
          },
          {
            field: 'startCourse',
            headerText: '코스',
            width: 50,
            textAlign: 'center',
            isCommonCodeField: true,
            groupCode: 'COURSE_CODE',
            allowEditing: false,
          },
          {
            field: 'visitName',
            headerText: '내장객',
            textAlign: 'left',
            isNumericType: true,
            width: 65,
            type: 'number',
            allowEditing: false,
          },
          {
            field: 'lockerNo',
            headerText: '락카',
            textAlign: 'center',
            width: 40,
            type: 'string',
            allowEditing: false,
          },
          {
            field: 'sexCode',
            headerText: '성별',
            textAlign: 'center',
            isCommonCodeField: true,
            groupCode: 'SEX_CODE',
            width: 40,
            type: 'string',
            allowEditing: false,
          },
          {
            field: 'contactTel',
            headerText: '연락처',
            type: 'string',
            textAlign: 'center',
            width: 93,
            allowEditing: false,
            valueAccessor: (field, data) => gridUtilGetTelColumnAccess(field, data),
          },
        ],
      };
    },
  },
  watch: {
    async byTeamOrGroup() {
      await this.fetchTeamInformation();
    },
  },
  mounted() {
    this.fetchSmsSendTypeOptions();
    this.fetchTeamInformation();
  },
  methods: {
    numberWithCommas,
    validateFormRefs,
    getByteLength,
    async resize() {
      if (this.$refs.aditWords) {
        const container = document.querySelector(".screen-box");
        const saved = container.scrollTop;
        this.$refs.aditWords.style.height = 0;
        this.$refs.aditWords.style.height = `${this.$refs.aditWords?.scrollHeight}px`;
        await this.$nextTick();
        container.scrollTop = saved;
      }
    },
    getByteLengthSum(text) {
      const textContent = [this.smsSendInfo.message, text];
      let byte = 0;
      textContent.forEach((text) => {
        if (text) {
          byte += this.getByteLength(text);
        }
      });
      return byte;
    },
    async fetchSmsSendTypeOptions(){
      const commonCodesGetCommonCodeByIdxAttrbs = commonCodesGetCommonCodeByIdxAttrb("SMS_SEND_TYPE", 2, "CADDIE_VISIT");
      const sendTypeArr = commonCodesGetCommonCodeByIdxAttrbs.map(item => ({
        smsSendType: item.comCode,
        smsSendTypeName: item.comName,
        sortNo: item.sortNo
      })).sort((a, b) => a.sortNo - b.sortNo);

      this.smsSendInfo.type = sendTypeArr[0].smsSendType;
      this.smsSendTypeOptionsList = sendTypeArr;

      await this.fetchSmsWords();
    },
    async fetchSmsWords() {
      this.smsWords = this.isOptionsVisible
          ? await GolfErpAPI.fetchSmsWords()
          : await GolfErpAPI.fetchAllSmsWords();

      this.searchOptions.smsSendTypeOptions = this.smsWords
          .filter(item => [this.smsSendInfo.type].includes(item.smsSendType)
          )
          .filter(item =>
              item.smsSendType
                  ? !this.isOptionsVisible
                      ? item
                      : commonCodesGetCommonCodeAttrbByCodeAndIdx(
                      "SMS_SEND_TYPE",
                      item.smsSendType,
                      1
                  ) === "Y"
                  : item
          );

      const smsSendType = this.searchOptions.smsSendTypeOptions.find(
          (data) => data.smsSendType === this.smsSendInfo.type
      );

      if (smsSendType) {
        this.smsSendInfo.type = smsSendType.smsSendType;
        this.smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
        this.smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
        this.smsSendInfo.smsKind = smsSendType.smsKind;
        this.smsSendInfo.subject = smsSendType.smsSendTypeName;
        this.smsSendInfo.message = smsSendType.smsWords;
        this.aditWords = smsSendType.aditWords || '';

        if (this.smsSendInfo.bizNameLocation === "DOWN") {
          this.smsSendInfo.message =
              JSON.stringify(this.smsSendInfo.message).toString().indexOf("[[HEADER_MEMO]$]\\n") > 0 ?
                  JSON.parse(JSON.stringify(this.smsSendInfo.message).replace("[[HEADER_MEMO]$]\\n", "")) :
                  JSON.parse(JSON.stringify(this.smsSendInfo.message).replace("[[HEADER_MEMO]$]", ""));
        }
      }

      this.smsSendTypeSetting();
    },
    async smsSendTypeSetting() {
      const smsSendType = this.searchOptions.smsSendTypeOptions.find(
          (data) => data.smsSendType === this.smsSendInfo.type
      );

      this.smsSendInfo.dsptchNo = getFormattedTelNumber(
          smsSendType.dsptchNo
              ? smsSendType.dsptchNo
              : commonCodesGetStandardInfo("dsptchNo")
      );

      if (this.smsSendInfo.type === 'C_CART_NOTI') {
        this.smsSendInfo.transSmsSendFlag = false;
      }

      this.isLocalClose = this.smsSendInfo.type === "C_CART_NOTI";

      if (this.$refs.aditWords) {
        this.$refs.aditWords.focus();
      }

      this.$nextTick(() => {
        this.resize();
      });
    },
    async onSmsSendTypeSelect(args) {
      this.smsSendInfo.type = args.itemData.comCode;
      await this.fetchSmsWords();
    },
    queryCellInfo(args) {
      const {
        cell,
        column: {field, edit},
      } = args;
      if (field === "select") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (!(edit.parent.sortedColumns.length > 0)) {
        if (this.grpNo && this.byTeamOrGroup === "GROUP" && args.data.groupNumLineFlag) {
          cell.classList.add(
              this.$t("className.grid.devReservationPartDivLine")
          );
        }
      }
    },
    async fetchTeamInformation() {
      const grpNo = this.byTeamOrGroup === 'TEAM' ? "" : this.grpNo;

      const {data} = await getVisitorInfoByCaddieArrangeMent(this.visitDate, this.chkinId, grpNo);
      this.teamInformation = data.map((item, idx) => ({
        select: true,
        _rid: idx + 1,
        chkinId: item.chkinId,
        visitSno: item.visitSno,
        visitName: item.visitName,
        visitId: item.visitId,
        lockerNo: item.lockerNo,
        sexCode: item.sexCode,
        contactTel: item.contactTel,
        startCourse: item.tgVisitTeam.startCourse,
        startTime: item.tgVisitTeam.startTime
      })).sort((a, b) => a._rid - b._rid);

      let beforeChkinId = this.teamInformation[0].chkinId;
      this.teamInformation.forEach((item) => {
        if (item.chkinId !== beforeChkinId) {
          item.groupNumLineFlag = true;
          beforeChkinId = item.chkinId;
        } else {
          item.groupNumLineFlag = false;
        }
      });

      // teamInformation.select = true 일 경우 toExcludeIndexList에  teamInformation._rid 담기
      // teamInformation.select = false 일 경우 아래코드 필요없음
      this.toExcludeIndexList = this.teamInformation.map(item => item._rid);
    },
    async gridCheckboxChanged(args) {
      const {
        columnName: field,
        rowData,
      } = args;

      if (field === "select") {
        // this.toExcludeIndexList 있는지 없는지 없으면 -1반환
        const hasRIdx = this.toExcludeIndexList
            ?.findIndex(item => item === rowData._rid);
        // this.teamInformation에서 인덱스 번호 반환
        const findIndex = this.teamInformation
            ?.findIndex(item => item._rid === rowData._rid);

        if (findIndex > -1) {
          if (hasRIdx > -1) {
            this.teamInformation[findIndex].select = false;
            delete this.toExcludeIndexList[hasRIdx];
            this.toExcludeIndexList = this.toExcludeIndexList
                ?.filter(item => !!item);
          } else {
            this.teamInformation[findIndex].select = true;
            this.toExcludeIndexList.push(rowData._rid);
          }
          this.$forceUpdate();
        }
      }
    },
    onGridHeaderSelectAllCheckboxClicked(args) {
      const {
        field,
        value,
      } = args;

      if (field === "select") {
        this.toExcludeIndexList = [];
        this.teamInformation.map(item => item.select = false);

        if (value) {
          const filteredRecords = this.$refs.builtInGuestGrid.getFilteredRecords();
          if (filteredRecords.length > 0) {
            filteredRecords.forEach(record => {
              record.select = value;
              this.toExcludeIndexList.push(record._rid);
            });
          } else {
            this.teamInformation.map(item => {
              item.select = value;
              this.toExcludeIndexList.push(item._rid);
            });
          }
        }
      }
    },
    async onSmsSendButtonClicked() {
      if (!this.validateFormRefs(this.smsSendValidationRules)) {
        return;
      }

      if (!(await this.confirm("SMS를 전송하시겠습니까?"))) {
        return;
      }

      await this.smsSend();
    },
    validation() {

    },
    async smsSend(contactTel = null) {
      let smsRequestList = [];
      let message = [];

      if (this.smsSendInfo.message) {
        message.push(this.smsSendInfo.message);
      }
      if (this.smsSendInfo.aditWordsFlag) {
        message.push(this.aditWords);
      }
      if (this.smsSendInfo.bizNameLocation === "DOWN") {
        message.push("[HEADER_MEMO]$");
      }
      message = message.join("\n");

      // 검증 SMS 전송
      if (contactTel) {
        const item = this.teamInformation[0];
        const block = this.makeSmsInfoTemplate(item, message);
        block.receiverNumber = contactTel;
        smsRequestList.push(block);
        await GolfErpAPI.sendSMS(smsRequestList);
        this.infoToast(this.$t("sms.popupMessage.success"));
        return;
      }

      // 실제 SMS 전송
      if (!(this.teamInformation.length > 0)) {
        this.errorToast("조회된 데이터가 없습니다");
        return;
      }

      const selectData = this.teamInformation.filter(item => this.toExcludeIndexList.includes(item._rid));
      if (!(selectData.length > 0)) {
        this.errorToast("선택된 내장객이 없습니다");
        return;
      }

      const isNotHaveContactTelAllCheck = selectData
          .map(item => item.contactTel ? true : false)
          .every(item => !item);
      if (isNotHaveContactTelAllCheck) {
        this.errorToast("선택하신 내장객의 연락처가 없습니다");
        return;
      }

      let filter = selectData.filter(item => item.contactTel);
      filter.forEach(item => {
        smsRequestList.push(this.makeSmsInfoTemplate(item, message));
      });
      await GolfErpAPI.sendSMS(smsRequestList);

      const havaContactTelVisitors = selectData.filter(item => item.contactTel).length;
      this.infoToast(`선택하신 ${selectData.length}건 중 연락처가 존재하는 ${havaContactTelVisitors}건의 SMS 전송이 완료되었습니다`);

    },
    makeSmsInfoTemplate(data, message) {
      return {
        type: this.smsSendInfo.type,
        kakaoFlag: this.smsSendInfo.kakaoSendFlag,
        transSmsSendFlag: this.smsSendInfo.transSmsSendFlag,
        smsKind: this.smsSendInfo.kakaoSendFlag
            ? this.smsSendInfo.smsKind
            : null,
        kakaoDispatchKey: this.smsSendInfo.kakaoSendFlag
            ? this.smsSendInfo.kakaoDispatchKey
            : null,
        kakaoTemplateKey: this.smsSendInfo.kakaoSendFlag
            ? this.smsSendInfo.kakaoTemplateCode
            : null,
        key: data.visitId,
        reservedFlag: this.smsSendInfo.reservedFlag,
        reserveDateTime:
            this.smsSendInfo.reservedFlag === true
                ? this.smsSendInfo.resveSendDate +
                " " +
                this.smsSendInfo.resveSendTime +
                ":00"
                : null,
        receiverName: data.visitName,
        receiverNumber: data.contactTel,
        subject: this.smsSendInfo.subject,
        message,
        replaceDataMap: {
          headerMemo: this.smsSendInfo.bizName,
        },
        dispatchNumber: this.smsSendInfo.dsptchNo.replace(/-/gi, ""),
        sender: "ERP",
      };
    },
    onTestButtonClicked() {
      if (!this.validateFormRefs(this.smsSendValidationRules)) {
        return;
      }
      this.showTestSmsSendPopup = true;
      this.$nextTick(() => {
        this.$refs.testSmsSendPopup.showPopup();
      });
    },
    async onInitButtonClicked() {
      this.teamInformation.map(item => item.select = true);
      this.byTeamOrGroup = 'TEAM';
      this.smsSendInfo.type = this.smsSendTypeOptionsList[0].smsSendType;

      this.smsSendInfo.aditWordsFlag = true;
      this.smsSendInfo.transSmsSendFlag = false;
      this.smsSendInfo.reservedFlag = false;
      this.smsSendInfo.resveSendDate = getFormattedDate(new Date());
      this.smsSendInfo.resveSendTime = "00:00";

      await this.fetchSmsWords();
    },
    onTestSmsSendPopup() {
      this.showTestSmsSendPopup = false;
    },
    onBuiltInGuestNotificationTalkSendPopupClosed() {
      this.$emit("popupClosed");
    },
    closeBatchSmsSendPopup() {
      this.$refs.builtInGuestNotificationTalkSendPopup.hide();
    },
  },
};
</script>


