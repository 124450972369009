<template>
  <div>
    <ejs-dialog
      ref="caddieArrangementNoticePopupDialog"
      allowDragging="true"
      header="알림 문구"
      height="800"
      isModal="true"
      showCloseIcon="true"
      width="800"
      :animationSettings="{ effect: 'None' }"
      v-bind="caddieArrangementNoticePopupDialogProps"
      @open="handleDialogOpen"
      @close="handleDialogClose"
    >
      <div class="window notificationPhrase">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">배치표 알림 문구 출력</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="lookup keyColor">
                          <erp-button
                              buttonDiv="GET"
                              :isShortcutButton="true"
                              @click.native="handleFetchClick"
                          >
                            조회
                          </erp-button>
                        </li>
                        <li class="add">
                          <erp-button
                              buttonDiv="SAVE"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              :shortcut="{key: 'F3'}"
                              shortcut-key="caddieArrangementNoticePopup.shortcuts.add"
                              @click.native="handleAddClick"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              buttonDiv="DELETE"
                              :is-icon-custom="true"
                              :is-shortcut-button="true"
                              @click.native="handleDeleteClick"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        gridLines="Both"
                        height="100%"
                        ref="caddieArrangementNoticeGrid"
                        rowHeight="24"
                        width="100%"
                        :provides="grid"
                        v-bind="caddieArrangementNoticeGridProps"
                        @cellSaved="handleCellSaved"
                        @queryCellInfo="caddieArrangementNoticeGridQueryCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  :isShortcutButton="true"
                  :isIconCustom="true"
                  @click.native="handleSaveClick"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="handleCloseClick"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
      <div style="display: none"></div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import _ from "lodash";
import {Edit, ForeignKey, Resize} from "@syncfusion/ej2-vue-grids";
import {
  getCaddieArrangementNotice,
  updateCaddieArrangementNotice,
} from "@/api/caddieArgmt";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";

export default {
  name: "CaddieArrangementNoticePopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    ErpButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  provide: {
    grid: [Edit, Resize],
  },
  data() {
    return {
      tcketNtcnList: [],
      grid: [Edit, ForeignKey, Resize],
    };
  },
  methods: {
    hideDialog() {
      this.$refs["caddieArrangementNoticePopupDialog"].hide();
    },
    showDialog() {
      this.fetch().then(() => {
        this.$refs["caddieArrangementNoticePopupDialog"].show();
      });
    },
    handleDialogOpen() {
      this.$emit("open");
    },
    handleDialogClose() {
      this.$emit("close");
    },

    handleFetchClick() {
      this.fetch();
    },
    handleAddClick() {
      this.addRecordIntoGrid();
    },
    handleDeleteClick() {
      this.removeSelectedRecordsFromGrid();
    },
    handleSaveClick() {
      this.updateCaddieArrangementNotice();
    },
    handleCloseClick() {
      this.hideDialog();
    },

    handleCellSaved(args) {
      const {
        columnName,
        value,
        rowData: { _rid },
      } = args;
      if (columnName === "sortNo") {
        const gridRef = this.$refs["caddieArrangementNoticeGrid"];
        const editedRecords = gridRef.getBatchCurrentViewRecords();

        const duplicateSortNoRecords = _.filter(
          editedRecords,
          (r) => r["sortNo"] === value && r["_rid"] !== _rid
        );
        if (!(duplicateSortNoRecords.length < 0)) {
          const { sortNo: maxSortNo } = _.maxBy(editedRecords, "sortNo");

          let nextSortNo = maxSortNo + 1;
          _.forEach(duplicateSortNoRecords, (r) => {
            const targetRowIndex = _.findIndex(
              editedRecords,
              (editedRecord) => r["_rid"] === editedRecord["_rid"]
            );
            if (!(targetRowIndex < 0)) {
              gridRef.ej2Instances.updateCell(
                targetRowIndex,
                "sortNo",
                nextSortNo
              );
              nextSortNo++;
            }
          });
        }
      }
    },

    // features
    addRecordIntoGrid() {
      const gridRef = this.$refs["caddieArrangementNoticeGrid"];
      const editedRecords = gridRef.getBatchCurrentViewRecords();
      const { _rid } = _.maxBy(editedRecords, "_rid") || { _rid:0 };
      const { sortNo: maxSortNo } = _.maxBy(editedRecords, "sortNo") || { sortNo:0 };

      gridRef.addRecord({
        _rid: _rid + 1,
        ntcnMessage: "",
        sortNo: maxSortNo + 1,
        useFlag: true,
      });
    },
    removeSelectedRecordsFromGrid() {
      this.$refs["caddieArrangementNoticeGrid"].deleteRecord();
    },

    // API
    async fetch() {
      try {
        const {
          value: { tcketNtcnList },
        } = await getCaddieArrangementNotice();

        this.tcketNtcnList = tcketNtcnList.map((item, index) => {
          return {
            ...item,
            _rid: index,
          };
        });
      } catch (e) {
        console.error(e);
      }
    },
    async updateCaddieArrangementNotice() {
      try {
        const { addedRecords, changedRecords, deletedRecords } = this.$refs[
          "caddieArrangementNoticeGrid"
        ].getBatchChanges();

        if (
          !this.validateCaddieRecords(addedRecords) ||
          !this.validateCaddieRecords(changedRecords)
        ) {
          this.errorToast("입력하지 않은 알림메시지가 있습니다");
          return;
        }

        await updateCaddieArrangementNotice(
          addedRecords,
          changedRecords,
          deletedRecords
        );

        this.fetch();

        this.infoToast(this.$t("main.popupMessage.saved"));
      } catch (e) {
        console.error(e);
      }
    },

    // validation
    validateCaddieRecords(records) {
      return _.findIndex(records, ({ ntcnMessage }) => !ntcnMessage) < 0;
    },
    caddieArrangementNoticeGridQueryCellInfo(args) {
      const {
        column: {
          field
        }
      } = args;

      if (
        field === "sortNo" ||
        field === "ntcnMessage" ||
        field === "useFlag"
      ) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    }
  },
  computed: {
    caddieArrangementNoticePopupDialogProps() {
      return {
        animationSettings: { effect: "None" },
        position: { x: "center", y: "center" },
        visible: this.visible,
      };
    },
    caddieArrangementNoticeGridProps() {
      return {
        allowResizing: true,
        allowSelection: true,
        isInPopup: true,
        columns: [
          {
            allowEditing: true,
            field: "sortNo",
            headerText: "정렬",
            minWidth: 16,
            textAlign: "Center",
            type: "number",
            width: 70,
          },
          {
            allowEditing: true,
            field: "ntcnMessage",
            headerText: "알림메시지",
            minWidth: 16,
            textAlign: "Left",
          },
          {
            allowEditing: true,
            displayAsCheckBox: true,
            editType: "booleanedit",
            field: "useFlag",
            headerText: "사용",
            minWidth: 16,
            textAlign: "Center",
            type: "boolean",
            width: 70,
          },
        ],
        dataSource: this.tcketNtcnList,
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Multiple",
          mode: "Both",
          enableToggle: false,
        },
      };
    },
  },
};
</script>
